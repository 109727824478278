export const TARGET_TWO_MESSAGES = {
    "admi.007.001.01": {
        "title": "admi.007.001.01",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--admi.007_RTGS_ReceiptAcknowledgement_Error_bs013-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:admi.007.001.01\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:admi.007.001.01 RTGS_ReceiptAcknowledgement_admi_007_ReceiptAcknowledgement_admi_007_001_01_20200204_1602.xsd\">\n" +
            "    <RctAck>\n" +
            "        <MsgId>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "        </MsgId>\n" +
            "        <Rpt>\n" +
            "            <RltdRef>\n" +
            "                <Ref>Inc050b013-BAHId</Ref>\n" +
            "            </RltdRef>\n" +
            "            <ReqHdlg>\n" +
            "                <StsCd>H001</StsCd>\n" +
            "                <Desc>Element Related is misssing</Desc>\n" +
            "            </ReqHdlg>\n" +
            "        </Rpt>\n" +
            "    </RctAck>\n" +
            "</Document>"
    },
    "camt.025.001.05": {
        "title": "camt.025.001.05",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--camt.025_RTGS_Receipt_SSTS_bs041-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.025.001.05\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.025.001.05 RTGS_Receipt_camt_025_Receipt_camt_025_001_05_20191024_1549.xsd\">\n" +
            "    <Rct>\n" +
            "        <MsgHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <ReqTp>\n" +
            "                <Prtry>\n" +
            "                    <Id>SSTS</Id>\n" +
            "                </Prtry>\n" +
            "            </ReqTp>\n" +
            "        </MsgHdr>\n" +
            "        <RctDtls>\n" +
            "            <OrgnlMsgId>\n" +
            "                <MsgId>Inc050b041-BAHId</MsgId>\n" +
            "            </OrgnlMsgId>\n" +
            "            <ReqHdlg>\n" +
            "                <StsCd>SSET</StsCd>\n" +
            "            </ReqHdlg>\n" +
            "        </RctDtls>\n" +
            "    </Rct>\n" +
            "</Document>"
    },
    "camt.029.001.09": {
        "title": "camt.029.001.09",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--Outbound_camt.029_RTGS_PaymentCancellationRequestStatus_Rejection_bs022-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.029.001.09\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.029.001.09 RTGS_ResolutionOfInvest_camt_029_ResolutionOfInvestigation_camt_029_001_09_20191025_1346.xsd\">\n" +
            "    <RsltnOfInvstgtn>\n" +
            "        <Assgnmt>\n" +
            "            <Id>NONREF</Id>\n" +
            "            <Assgnr>\n" +
            "                <Agt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Agt>\n" +
            "            </Assgnr>\n" +
            "            <Assgne>\n" +
            "                <Agt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Agt>\n" +
            "            </Assgne>\n" +
            "            <CreDtTm>2019-10-07T10:30:00.001+00:00</CreDtTm>\n" +
            "        </Assgnmt>\n" +
            "        <Sts>\n" +
            "            <Conf>RJCR</Conf>\n" +
            "        </Sts>\n" +
            "        <CxlDtls>\n" +
            "            <TxInfAndSts>\n" +
            "                <OrgnlGrpInf>\n" +
            "                    <OrgnlMsgId>Inp008b022-BAHId</OrgnlMsgId>\n" +
            "                    <OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "                </OrgnlGrpInf>\n" +
            "                <OrgnlEndToEndId>Inp008b022-E2EId</OrgnlEndToEndId>\n" +
            "                <OrgnlUETR>e008b022-59c5-41e9-be4c-d45102fc201e</OrgnlUETR>\n" +
            "                <CxlStsRsnInf>\n" +
            "                    <Rsn>\n" +
            "                        <Prtry>LEGL</Prtry>\n" +
            "                    </Rsn>\n" +
            "                    <AddtlInf>Legal decision</AddtlInf>\n" +
            "                </CxlStsRsnInf>\n" +
            "            </TxInfAndSts>\n" +
            "        </CxlDtls>\n" +
            "    </RsltnOfInvstgtn>\n" +
            "</Document>"
    },
    "camt.050.001.05": {
        "title": "camt.050.001.05",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--camt.050_RTGS_LiquidityCreditTransfer_DCAT2S_bs044-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.050.001.05\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.050.001.05 RTGS_LiquidityTransfer_camt_050_LiquidityCreditTransfer_camt_050_001_05_20191024_1551.xsd\">\n" +
            "    <LqdtyCdtTrf>\n" +
            "        <MsgHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "        </MsgHdr>\n" +
            "        <LqdtyCdtTrf>\n" +
            "            <LqdtyTrfId>\n" +
            "                <EndToEndId>Inc050b044-E2EId</EndToEndId>\n" +
            "            </LqdtyTrfId>\n" +
            "            <CdtrAcct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>ERTGSC0DEEUR001</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </CdtrAcct>\n" +
            "            <TrfdAmt>\n" +
            "                <AmtWthCcy Ccy=\"EUR\">200000.00</AmtWthCcy>\n" +
            "            </TrfdAmt>\n" +
            "            <DbtrAcct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>RTGSDCPBAADEFFAC1EUR0A01</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </DbtrAcct>\n" +
            "            <SttlmDt>2019-10-07</SttlmDt>\n" +
            "        </LqdtyCdtTrf>\n" +
            "    </LqdtyCdtTrf>\n" +
            "</Document>\n"
    },
    "camt.053.001.08": {
        "title": "camt.053.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--camt.053_RTGS_BankToCustomerStatement_bs998 -->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.053.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.053.001.08 RTGS_camt_053_BankToCustomerStatement_camt_053_001_0.xsd\">\n" +
            "    <BkToCstmrStmt>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2019-10-08T18:02:00.001+00:00</CreDtTm>\n" +
            "            <MsgPgntn>\n" +
            "                <PgNb>1</PgNb>\n" +
            "                <LastPgInd>true</LastPgInd>\n" +
            "            </MsgPgntn>\n" +
            "        </GrpHdr>\n" +
            "        <Stmt>\n" +
            "            <Id>00001</Id>\n" +
            "            <Acct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>RTGSDCPBAADEFFAC2EUR0A01</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </Acct>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>OPBD</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"EUR\">0</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2019-10-08</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>CLBD</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"EUR\">123500.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2019-10-08</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <TxsSummry>\n" +
            "                <TtlNtries>\n" +
            "                    <NbOfNtries>2</NbOfNtries>\n" +
            "                </TtlNtries>\n" +
            "            </TxsSummry>\n" +
            "            <Ntry>\n" +
            "                <NtryRef>RTGS-p008b021</NtryRef>\n" +
            "                <Amt Ccy=\"EUR\">23500.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Sts>\n" +
            "                    <Cd>BOOK</Cd>\n" +
            "                </Sts>\n" +
            "                <BookgDt>\n" +
            "                    <DtTm>2019-10-08T10:15:00.001+00:00</DtTm>\n" +
            "                </BookgDt>\n" +
            "                <ValDt>\n" +
            "                    <Dt>2019-10-08</Dt>\n" +
            "                </ValDt>\n" +
            "                <BkTxCd>\n" +
            "                    <Prtry>\n" +
            "                        <Cd>PMNT</Cd>\n" +
            "                    </Prtry>\n" +
            "                </BkTxCd>\n" +
            "                <NtryDtls>\n" +
            "                    <TxDtls>\n" +
            "                        <Refs>\n" +
            "                            <InstrId>Inp008b021-InsId</InstrId>\n" +
            "                            <EndToEndId>Inp008b021-E2EId</EndToEndId>\n" +
            "                            <UETR>e008b021-59c5-41e9-be4c-d45102fc201e</UETR>\n" +
            "                        </Refs>\n" +
            "                        <RltdPties>\n" +
            "                            <Dbtr>\n" +
            "                                <Pty>\n" +
            "                                    <Nm>Debtor name</Nm>\n" +
            "                                    <Id>\n" +
            "                                        <OrgId>\n" +
            "                                            <AnyBIC>DEBTORXXBIC</AnyBIC>\n" +
            "                                        </OrgId>\n" +
            "                                    </Id>\n" +
            "                                </Pty>\n" +
            "                            </Dbtr>\n" +
            "                            <Cdtr>\n" +
            "                                <Pty>\n" +
            "                                    <Nm>Creditor name</Nm>\n" +
            "                                    <Id>\n" +
            "                                        <OrgId>\n" +
            "                                            <AnyBIC>CREDITORBIC</AnyBIC>\n" +
            "                                        </OrgId>\n" +
            "                                    </Id>\n" +
            "                                </Pty>\n" +
            "                            </Cdtr>\n" +
            "                        </RltdPties>\n" +
            "                        <RltdAgts>\n" +
            "                            <InstgAgt>\n" +
            "                                <FinInstnId>\n" +
            "                                    <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                                </FinInstnId>\n" +
            "                            </InstgAgt>\n" +
            "                            <InstdAgt>\n" +
            "                                <FinInstnId>\n" +
            "                                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                                </FinInstnId>\n" +
            "                            </InstdAgt>\n" +
            "                        </RltdAgts>\n" +
            "                    </TxDtls>\n" +
            "                </NtryDtls>\n" +
            "            </Ntry>\n" +
            "            <Ntry>\n" +
            "                <NtryRef>RTGS-c050b041</NtryRef>\n" +
            "                <Amt Ccy=\"EUR\">100000.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Sts>\n" +
            "                    <Cd>BOOK</Cd>\n" +
            "                </Sts>\n" +
            "                <BookgDt>\n" +
            "                    <DtTm>2019-10-08T11:18:02.001+00:00</DtTm>\n" +
            "                </BookgDt>\n" +
            "                <ValDt>\n" +
            "                    <Dt>2019-10-08</Dt>\n" +
            "                </ValDt>\n" +
            "                <BkTxCd>\n" +
            "                    <Prtry>\n" +
            "                        <Cd>LIQT</Cd>\n" +
            "                    </Prtry>\n" +
            "                </BkTxCd>\n" +
            "                <NtryDtls>\n" +
            "                    <TxDtls>\n" +
            "                        <Refs>\n" +
            "                            <InstrId>Inc050b041-BAHId</InstrId>\n" +
            "                            <EndToEndId>Inc050b041-E2EId</EndToEndId>\n" +
            "                        </Refs>\n" +
            "                        <RltdPties>\n" +
            "                            <DbtrAcct>\n" +
            "                                <Id>\n" +
            "                                    <Othr>\n" +
            "                                        <Id>RTGSDCPBBBDEFFXXXEUR0A01</Id>\n" +
            "                                    </Othr>\n" +
            "                                </Id>\n" +
            "                            </DbtrAcct>\n" +
            "                            <CdtrAcct>\n" +
            "                                <Id>\n" +
            "                                    <Othr>\n" +
            "                                        <Id>RTGSDCPBAADEFFAC2EUR0A01</Id>\n" +
            "                                    </Othr>\n" +
            "                                </Id>\n" +
            "                            </CdtrAcct>\n" +
            "                        </RltdPties>\n" +
            "                        <LclInstrm>\n" +
            "                            <Prtry>LIIA</Prtry>\n" +
            "                        </LclInstrm>\n" +
            "                    </TxDtls>\n" +
            "                </NtryDtls>\n" +
            "            </Ntry>\n" +
            "        </Stmt>\n" +
            "    </BkToCstmrStmt>\n" +
            "</Document>\n"
    },
    "camt.054.001.08": {
        "title": "camt.054.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--camt.054_RTGS_CreditNotification_LIQT_bs070-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.054.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.054.001.08 RTGS_camt_054_BankToCustomerDebitCredi.xsd\">\n" +
            "    <BkToCstmrDbtCdtNtfctn>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2019-10-07T13:10:05.001+00:00</CreDtTm>\n" +
            "        </GrpHdr>\n" +
            "        <Ntfctn>\n" +
            "            <Id>Ouc054b070-BAHId</Id>\n" +
            "            <Acct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>RTGSDCPBBBDEFFXXXEUR0A01</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </Acct>\n" +
            "            <Ntry>\n" +
            "                <NtryRef>RTGS-c050b070</NtryRef>\n" +
            "                <Amt Ccy=\"EUR\">252000.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Sts>\n" +
            "                    <Cd>BOOK</Cd>\n" +
            "                </Sts>\n" +
            "                <BookgDt>\n" +
            "                    <DtTm>2019-10-07T13:10:00.001+00:00</DtTm>\n" +
            "                </BookgDt>\n" +
            "                <BkTxCd>\n" +
            "                    <Prtry>\n" +
            "                        <Cd>LIQT</Cd>\n" +
            "                    </Prtry>\n" +
            "                </BkTxCd>\n" +
            "                <NtryDtls>\n" +
            "                    <TxDtls>\n" +
            "                        <Refs>\n" +
            "                            <InstrId>Inc050b070-BAHId</InstrId>\n" +
            "                            <EndToEndId>Inc050b070-E2EId</EndToEndId>\n" +
            "                        </Refs>\n" +
            "                        <Amt Ccy=\"EUR\">252000.00</Amt>\n" +
            "                        <RltdPties>\n" +
            "                            <DbtrAcct>\n" +
            "                                <Id>\n" +
            "                                    <Othr>\n" +
            "                                        <Id>CLMMCAPBCCDEFFXXXEUR0A01</Id>\n" +
            "                                    </Othr>\n" +
            "                                </Id>\n" +
            "                            </DbtrAcct>\n" +
            "                            <CdtrAcct>\n" +
            "                                <Id>\n" +
            "                                    <Othr>\n" +
            "                                        <Id>RTGSDCPBBBDEFFXXXEUR0A01</Id>\n" +
            "                                    </Othr>\n" +
            "                                </Id>\n" +
            "                            </CdtrAcct>\n" +
            "                        </RltdPties>\n" +
            "                        <LclInstrm>\n" +
            "                            <Prtry>LIIE</Prtry>\n" +
            "                        </LclInstrm>\n" +
            "                    </TxDtls>\n" +
            "                </NtryDtls>\n" +
            "            </Ntry>\n" +
            "        </Ntfctn>\n" +
            "    </BkToCstmrDbtCdtNtfctn>\n" +
            "</Document>"
    },
    "camt.056.001.08": {
        "title": "camt.056.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--Inbound_camt.056_RTGS_PaymentCancellationRequest_bs031-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.056.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.056.001.08 RTGS_ResolutionOfInvest_camt_056_FIToFIPaymentCancellationRequest_camt_056_001_08_20191025_1346.xsd\">\n" +
            "    <FIToFIPmtCxlReq>\n" +
            "        <Assgnmt>\n" +
            "            <Id>NONREF</Id>\n" +
            "            <Assgnr>\n" +
            "                <Agt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Agt>\n" +
            "            </Assgnr>\n" +
            "            <Assgne>\n" +
            "                <Agt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Agt>\n" +
            "            </Assgne>\n" +
            "            <CreDtTm>2019-10-07T12:00:00+00:00</CreDtTm>\n" +
            "        </Assgnmt>\n" +
            "        <Undrlyg>\n" +
            "            <TxInf>\n" +
            "                <OrgnlGrpInf>\n" +
            "                    <OrgnlMsgId>Inp010b031-BAHId</OrgnlMsgId>\n" +
            "                    <OrgnlMsgNmId>pacs.010.001.03</OrgnlMsgNmId>\n" +
            "                </OrgnlGrpInf>\n" +
            "                <OrgnlEndToEndId>Inp010b031-E2EId</OrgnlEndToEndId>\n" +
            "                <OrgnlUETR>e010b031-59c5-41e9-be4c-d45102fc201e</OrgnlUETR>\n" +
            "                <OrgnlIntrBkSttlmAmt Ccy=\"EUR\">53500</OrgnlIntrBkSttlmAmt>\n" +
            "                <OrgnlIntrBkSttlmDt>2019-10-07</OrgnlIntrBkSttlmDt>\n" +
            "                <CxlRsnInf>\n" +
            "                    <Orgtr>\n" +
            "                        <Id>\n" +
            "                            <OrgId>\n" +
            "                                <AnyBIC>PBAADEFFINV</AnyBIC>\n" +
            "                            </OrgId>\n" +
            "                        </Id>\n" +
            "                    </Orgtr>\n" +
            "                    <Rsn>\n" +
            "                        <Cd>CUST</Cd>\n" +
            "                    </Rsn>\n" +
            "                </CxlRsnInf>\n" +
            "            </TxInf>\n" +
            "        </Undrlyg>\n" +
            "    </FIToFIPmtCxlReq>\n" +
            "</Document>"
    },
    "pacs.002.001.10": {
        "title": "pacs.002.001.10",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--pacs.002_AS-D_FIPaymentStatusReport_ACSC_bs561-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10 RTGS_pacs_guidelines_pacs_002_FIToFIPaymentStatusReport_pacs_002_001_10_20191021_1536.xsd\">\n" +
            "    <FIToFIPmtStsRpt>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2020-02-07T09:00:02.001+00:00</CreDtTm>\n" +
            "        </GrpHdr>\n" +
            "        <TxInfAndSts>\n" +
            "            <OrgnlGrpInf>\n" +
            "                <OrgnlMsgId>Inp009b561-BAHId</OrgnlMsgId>\n" +
            "                <OrgnlMsgNmId>pacs.009.001.08CORE</OrgnlMsgNmId>\n" +
            "            </OrgnlGrpInf>\n" +
            "            <OrgnlInstrId>Inp009b561-InsId2</OrgnlInstrId>\n" +
            "            <OrgnlEndToEndId>Inp009b561-E2EIdB</OrgnlEndToEndId>\n" +
            "            <OrgnlUETR>e009b561-59c5-41e9-be4c-d45102fc201e</OrgnlUETR>\n" +
            "            <TxSts>ACSC</TxSts>\n" +
            "            <FctvIntrBkSttlmDt>\n" +
            "                <DtTm>2020-02-07T09:00:01.001+00:00</DtTm>\n" +
            "            </FctvIntrBkSttlmDt>\n" +
            "            <ClrSysRef>RTGS-p009b561-2</ClrSysRef>    \n" +
            "        </TxInfAndSts>\n" +
            "    </FIToFIPmtStsRpt>\n" +
            "</Document>"
    },
    "pacs.004.001.09": {
        "title": "pacs.004.001.09",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--Inbound_pacs.004_RTGS_PaymentReturnOrder_bs023-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.004.001.09\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.004.001.09 RTGS_pacs_guidelines_pacs_004_PaymentReturn_pacs_004_001_09_20191021_1541.xsd\">\n" +
            "    <PmtRtr>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2019-10-07T16:51:00+00:00</CreDtTm>\n" +
            "            <NbOfTxs>1</NbOfTxs>\n" +
            "            <SttlmInf>\n" +
            "                <SttlmMtd>CLRG</SttlmMtd>\n" +
            "                <ClrSys>\n" +
            "                    <Cd>TGT</Cd>\n" +
            "                </ClrSys>\n" +
            "            </SttlmInf>\n" +
            "        </GrpHdr>\n" +
            "        <TxInf>\n" +
            "            <OrgnlGrpInf>\n" +
            "                <OrgnlMsgId>Inp008b023-BAHId</OrgnlMsgId>\n" +
            "                <OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "            </OrgnlGrpInf>\n" +
            "            <OrgnlInstrId>Inp008b023-InsId</OrgnlInstrId>\n" +
            "            <OrgnlEndToEndId>Inp008b023-E2EId</OrgnlEndToEndId>\n" +
            "            <OrgnlUETR>e008b023-59c5-41e9-be4c-d45102fc201e</OrgnlUETR>\n" +
            "            <OrgnlIntrBkSttlmAmt Ccy=\"EUR\">74000</OrgnlIntrBkSttlmAmt>\n" +
            "            <OrgnlIntrBkSttlmDt>2019-10-06</OrgnlIntrBkSttlmDt>\n" +
            "            <RtrdIntrBkSttlmAmt Ccy=\"EUR\">4000</RtrdIntrBkSttlmAmt>\n" +
            "            <IntrBkSttlmDt>2019-10-07</IntrBkSttlmDt>\n" +
            "            <InstgAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstgAgt>\n" +
            "            <InstdAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstdAgt>\n" +
            "            <RtrChain>\n" +
            "                <UltmtDbtr>\n" +
            "                    <Pty>\n" +
            "                        <Nm>Ultimate debtor name</Nm>\n" +
            "                        <Id>\n" +
            "                            <OrgId>\n" +
            "                                <AnyBIC>ULTMDBTRBIC</AnyBIC>\n" +
            "                            </OrgId>\n" +
            "                        </Id>\n" +
            "                    </Pty>\n" +
            "                </UltmtDbtr>\n" +
            "                <Dbtr>\n" +
            "                    <Pty>\n" +
            "                        <Nm>Debtor name</Nm>\n" +
            "                        <Id>\n" +
            "                            <OrgId>\n" +
            "                                <AnyBIC>DEBTORXXBIC</AnyBIC>\n" +
            "                            </OrgId>\n" +
            "                        </Id>\n" +
            "                    </Pty>\n" +
            "                </Dbtr>\n" +
            "                <Cdtr>\n" +
            "                    <Pty>\n" +
            "                        <Nm>Creditor name</Nm>\n" +
            "                        <Id>\n" +
            "                            <OrgId>\n" +
            "                                <AnyBIC>CREDITORBIC</AnyBIC>\n" +
            "                            </OrgId>\n" +
            "                        </Id>\n" +
            "                    </Pty>\n" +
            "                </Cdtr>\n" +
            "                <UltmtCdtr>\n" +
            "                    <Pty>\n" +
            "                        <Nm>Ultimate creditor name</Nm>\n" +
            "                        <Id>\n" +
            "                            <OrgId>\n" +
            "                                <AnyBIC>ULTMCDTRBIC</AnyBIC>\n" +
            "                            </OrgId>\n" +
            "                        </Id>\n" +
            "                    </Pty>\n" +
            "                </UltmtCdtr>\n" +
            "            </RtrChain>\n" +
            "            <RtrRsnInf>\n" +
            "                <Rsn>\n" +
            "                    <Cd>CUST</Cd>\n" +
            "                </Rsn>\n" +
            "            </RtrRsnInf>\n" +
            "        </TxInf>\n" +
            "    </PmtRtr>\n" +
            "</Document>"
    },
    "pacs.008.001.08": {
        "title": "pacs.008.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--Inbound_pacs.008_RTGS_CustomerCreditTransferOrder_bs020-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08 RTGS_pacs_guidelines_pacs_008_FIToFICustomerCreditTransfer_pacs_008_001_08_20191021_1543.xsd\">\n" +
            "    <FIToFICstmrCdtTrf>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2019-10-07T09:30:00+00:00</CreDtTm>\n" +
            "            <NbOfTxs>1</NbOfTxs>\n" +
            "            <SttlmInf>\n" +
            "                <SttlmMtd>CLRG</SttlmMtd>\n" +
            "                <ClrSys>\n" +
            "                    <Cd>TGT</Cd>\n" +
            "                </ClrSys>\n" +
            "            </SttlmInf>\n" +
            "        </GrpHdr>\n" +
            "        <CdtTrfTxInf>\n" +
            "            <PmtId>\n" +
            "                <InstrId>Inp008b020-InsId</InstrId>\n" +
            "                <EndToEndId>Inp008b020-E2EId</EndToEndId>\n" +
            "                <UETR>e008b020-59c5-41e9-be4c-d45102fc201e</UETR>\n" +
            "            </PmtId>\n" +
            "            <IntrBkSttlmAmt Ccy=\"EUR\">18000</IntrBkSttlmAmt>\n" +
            "            <IntrBkSttlmDt>2019-10-27</IntrBkSttlmDt>\n" +
            "            <ChrgBr>DEBT</ChrgBr>\n" +
            "            <InstgAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstgAgt>\n" +
            "            <InstdAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstdAgt>\n" +
            "            <UltmtDbtr>\n" +
            "                <Nm>Ultimate debtor name</Nm>\n" +
            "                <Id>\n" +
            "                    <OrgId>\n" +
            "                        <AnyBIC>ULTMDBTRBIC</AnyBIC>\n" +
            "                    </OrgId>\n" +
            "                </Id>\n" +
            "            </UltmtDbtr>\n" +
            "            <Dbtr>\n" +
            "                <Nm>Debtor name</Nm>\n" +
            "                <Id>\n" +
            "                    <OrgId>\n" +
            "                        <AnyBIC>DEBTORXXBIC</AnyBIC>\n" +
            "                    </OrgId>\n" +
            "                </Id>\n" +
            "            </Dbtr>\n" +
            "            <DbtrAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>DEBTORXXAGT</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </DbtrAgt>\n" +
            "            <CdtrAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>CREDITORAGT</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </CdtrAgt>\n" +
            "            <Cdtr>\n" +
            "                <Nm>Creditor name</Nm>\n" +
            "                <Id>\n" +
            "                    <OrgId>\n" +
            "                        <AnyBIC>CREDITORBIC</AnyBIC>\n" +
            "                    </OrgId>\n" +
            "                </Id>\n" +
            "            </Cdtr>\n" +
            "            <UltmtCdtr>\n" +
            "                <Nm>Ultimate creditor name</Nm>\n" +
            "                <Id>\n" +
            "                    <OrgId>\n" +
            "                        <AnyBIC>ULTMCDTRBIC</AnyBIC>\n" +
            "                    </OrgId>\n" +
            "                </Id>\n" +
            "            </UltmtCdtr>\n" +
            "        </CdtTrfTxInf>\n" +
            "    </FIToFICstmrCdtTrf>\n" +
            "</Document>"
    },
    "pacs.009.001.08": {
        "title": "pacs.009.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--Inbound_pacs.009_RTGS_FICreditTransferOrder_COV_bs028-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08 RTGS_pacs_guidelines_pacs_009_FIToFIFinancialInstitutionCreditTransfer_pacs_009_001_08_20191021_1544%20(1).xsd\">\n" +
            "    <FICdtTrf>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2019-10-07T13:45:00+00:00</CreDtTm>\n" +
            "            <NbOfTxs>1</NbOfTxs>\n" +
            "            <SttlmInf>\n" +
            "                <SttlmMtd>CLRG</SttlmMtd>\n" +
            "                <ClrSys>\n" +
            "                    <Cd>TGT</Cd>\n" +
            "                </ClrSys>\n" +
            "            </SttlmInf>\n" +
            "        </GrpHdr>\n" +
            "        <CdtTrfTxInf>\n" +
            "            <PmtId>\n" +
            "                <InstrId>Inp009b028-InsId</InstrId>\n" +
            "                <EndToEndId>Inp008b028-E2EId</EndToEndId>\n" +
            "                <UETR>e008b028-59c5-41e9-be4c-d45102fc201e</UETR>\n" +
            "            </PmtId>\n" +
            "            <IntrBkSttlmAmt Ccy=\"EUR\">61250.00</IntrBkSttlmAmt>\n" +
            "            <IntrBkSttlmDt>2019-10-07</IntrBkSttlmDt>\n" +
            "            <InstgAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstgAgt>\n" +
            "            <InstdAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstdAgt>\n" +
            "            <Dbtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Dbtr>\n" +
            "            <Cdtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Cdtr>\n" +
            "            <UndrlygCstmrCdtTrf>\n" +
            "                <UltmtDbtr>\n" +
            "                    <Nm>Ultimate debtor name</Nm>\n" +
            "                    <Id>\n" +
            "                        <OrgId>\n" +
            "                            <AnyBIC>ULTMDBTRBIC</AnyBIC>\n" +
            "                        </OrgId>\n" +
            "                    </Id>\n" +
            "                </UltmtDbtr>\n" +
            "                <Dbtr>\n" +
            "                    <Nm>Debit customer name</Nm>\n" +
            "                    <PstlAdr>\n" +
            "                        <TwnNm>Frankfurt</TwnNm>\n" +
            "                        <Ctry>DE</Ctry>\n" +
            "                    </PstlAdr>\n" +
            "                </Dbtr>\n" +
            "                <DbtrAgt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </DbtrAgt>\n" +
            "                <CdtrAgt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBAADEFFXXX</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </CdtrAgt>\n" +
            "                <Cdtr>\n" +
            "                    <Nm>Credit customer name</Nm>\n" +
            "                    <PstlAdr>\n" +
            "                        <TwnNm>Dusseldorf</TwnNm>\n" +
            "                        <Ctry>DE</Ctry>\n" +
            "                    </PstlAdr>\n" +
            "                </Cdtr>\n" +
            "                <UltmtCdtr>\n" +
            "                    <Nm>Ultimate creditor name</Nm>\n" +
            "                    <Id>\n" +
            "                        <OrgId>\n" +
            "                            <AnyBIC>ULTMCDTRBIC</AnyBIC>\n" +
            "                        </OrgId>\n" +
            "                    </Id>\n" +
            "                </UltmtCdtr>\n" +
            "            </UndrlygCstmrCdtTrf>\n" +
            "        </CdtTrfTxInf>\n" +
            "    </FICdtTrf>\n" +
            "</Document>"
    },
    "pacs.010.001.03": {
        "title": "pacs.010.001.03",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<!--Inbound_pacs.010_RTGS_FIDirectDebitOrder_URGT_bs031-->\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.010.001.03\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.010.001.03 RTGS_pacs_guidelines_pacs_010_InterbankDirectDebit_FinancialInstitutionDirectDebit_pacs_010_001_03_20191021_1545.xsd\">\n" +
            "    <FIDrctDbt>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>NONREF</MsgId>\n" +
            "            <CreDtTm>2019-10-07T09:22:00+00:00</CreDtTm>\n" +
            "            <NbOfTxs>1</NbOfTxs>\n" +
            "        </GrpHdr>\n" +
            "        <CdtInstr>\n" +
            "            <CdtId>Inp010b031-CdtId</CdtId>\n" +
            "            <InstgAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstgAgt>\n" +
            "            <InstdAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstdAgt>\n" +
            "            <Cdtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>PBAADEFFAC2</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Cdtr>\n" +
            "            <DrctDbtTxInf>\n" +
            "                <PmtId>\n" +
            "                    <InstrId>Inp010b031-InsId</InstrId>\n" +
            "                    <EndToEndId>Inp010b031-E2EId</EndToEndId>\n" +
            "                    <UETR>e010b031-59c5-41e9-be4c-d45102fc201e</UETR>\n" +
            "                </PmtId>\n" +
            "                <IntrBkSttlmAmt Ccy=\"EUR\">53500.00</IntrBkSttlmAmt>\n" +
            "                <IntrBkSttlmDt>2019-10-07</IntrBkSttlmDt>\n" +
            "                <SttlmPrty>URGT</SttlmPrty>\n" +
            "                <Dbtr>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>PBBBDEFFXXX</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Dbtr>\n" +
            "            </DrctDbtTxInf>\n" +
            "        </CdtInstr>\n" +
            "    </FIDrctDbt>\n" +
            "</Document>"
    }

}